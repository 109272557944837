<template>
  <div class="claim-reward-page referal-program-page">
    <h1>Welcome to the BAP referral program</h1>

    <form
      v-if="!isSubmited"
      action="#"
      class="claim-reward-page-form"
      @submit.prevent="onSubmit"
    >
      <h2 class="claim-reward-page-form-title">
        Enter wallet address of up to 10 people you will refer
      </h2>
      <div class="claim-reward-page-input">
        <label>Your wallet</label>
        <!-- <input v-model="buyerAddress" /> -->
        <span
          v-if="buyerAddress"
          class="claim-reward-connect-wallet"
        >{{ buyerAddress }}</span>
        <a
          v-else
          class="claim-reward-connect-button"
          href="#"
          @click.prevent="onConnect"
        >Connect</a>
        <div
          v-if="errors.buyerAddress"
          class="claim-reward-page-input-error"
        >
          This field is required
        </div>
      </div>
      <div>
        <div
          v-for="(address, idx) in affiliateAddresses"
          :key="idx"
          class="claim-reward-page-input"
        >
          <label>Affiliate Address {{ idx + 1 }}</label>
          <div class="referal-program-page-input-wrapper">
            <input
              v-model="affiliateAddresses[idx]"
              type="text"
            >
            <svgMinus
              class="referal-program-page-minus"
              @click.prevent="removeAddress(idx)"
            />
          </div>
          <div
            v-if="errors.affiliateAddresses && errors.affiliateAddresses[idx]"
            class="claim-reward-page-input-error"
          >
            This field is required
          </div>
        </div>
        <button
          v-if="affiliateAddresses.length < 10"
          class="referal-program-page-add-address"
          type="button"
          @click.prevent="addAddress"
        >
          Add Address +
        </button>
      </div>

      <button
        type="submit"
        class="claim-reward-page-button"
      >
        SUBMIT
      </button>
    </form>
    <div
      v-else
      key="thanks"
      class="claim-reward-page-form"
    >
      <h2>Thank you, form was submitted.</h2>
    </div>
    <div class="claim-reward-page-description">
      Check the
      <router-link :to="{ name: 'rewardleaderboard' }">
        Leaderboard
      </router-link>
      to see when your reward was approved. <br>
      Ask any questions in Discord.
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { shorten } from "../helpers/utils";
import svgMinus from "../assets/icons/minus.svg?inline";
import { useWeb3Store } from "@/store/web3";
import { connectWallet } from "@/web3";
// import { Config } from "../config/";
export default {
  components: { svgMinus },
  props: {
    signMessage: Function,
  },
  data() {
    return {
      isSubmited: false,
      affiliateAddresses: [""],
      signature: "",
      message: "",
      errors: { affiliateAddresses: [] },
    };
  },
  computed: {
    buyerAddress() {
      return shorten(this.walletAddress, 4);
    },
    walletAddress() {
      return useWeb3Store().walletAddress;
    },
  },
  watch: {
    walletAddress() {
      this.signature = "";
    },
  },
  methods: {
    removeAddress(idx) {
      this.affiliateAddresses.splice(idx, 1);
    },
    addAddress() {
      if (this.affiliateAddresses.length > 10) return;
      this.affiliateAddresses.push("");
    },
    async onConnect() {
      await connectWallet()
    //   this.message = Date.now().toString();
    //   this.signature = await this.signMessage(this.message);
    },
    validate() {
      this.errors = {};
      if (!this.walletAddress) {
        this.errors.buyerAddress = true;
      }

      return !Object.keys(this.errors).length;
    },
    async onSubmit() {
      if (!this.validate()) return;
      try {
        // const response = await axios.post(`${Config.apiUrl}/mint/verify`, {
        //   wallet: this.walletAddress,
        //   referrerAddress: this.referrerAddress,
        //   txHash: this.txHash,
        //   signature: this.signature,
        //   message: this.message,
        // });
        // console.log("sended", response);
        console.log(this.affiliateAddresses, this.walletAddress)
        this.isSubmited = true;
      } catch (err) {
        alert("Error, please try again");
        console.log("error", err);
      }
    },
  },
};
</script>
